//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ModelSelect, ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";

import UMUM from "../../library/umum";


export default {
components: {
    ModelSelect,
    ModelListSelect
},

data() {
    return {
        form: {
            id: "",
            nip: "",
            penilai: "",
            atasan_penilai: "",

            bio_nama : '',
            bio_gelar_belakang : '',
            bio_gelar_depan : '',
            bio_gol : '',
            bio_pangkat : '',
            bio_ruang : '',
            bio_jabatan : '',
            bio_unit_kerja : '',

            penilai_nama : '',
            penilai_gelar_belakang : '',
            penilai_gelar_depan : '',
            penilai_pangkat : '',
            penilai_gol : '',
            penilai_ruang : '',
            penilai_jabatan : '',
            penilai_unit_kerja : '',

            ap_nama : '',
            ap_gelar_belakang : '',
            ap_gelar_depan : '',
            ap_pangkat : '',
            ap_gol : '',
            ap_ruang : '',
            ap_jabatan : '',
            ap_unit_kerja : '',


        },

        UMUM : UMUM,

        mdl_config : false
    };
},
methods: {
    getView: function() {
    //   this.cek_load_data = true;
        fetch(this.$store.state.url.URL_kinerja_asn + "view", {
        method: "POST",
        headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
            nip : this.form.nip
        })
        })
        .then(res => res.json())
        .then(res_data => {
            // console.log(res_data)

            var data = res_data[0]
            this.form.id = data.id;
            this.form.nip= data.nip;
            this.form.penilai= data.penilai;
            this.form.atasan_penilai= data.atasan_penilai;

            this.form.bio_nama = data.bio_nama;
            this.form.bio_gelar_belakang = data.bio_gelar_belakang;
            this.form.bio_gelar_depan = data.bio_gelar_depan;
            this.form.bio_gol = data.bio_gol;
            this.form.bio_pangkat = data.bio_pangkat;
            this.form.bio_ruang = data.bio_ruang;
            this.form.bio_jabatan = data.bio_jabatan;
            this.form.bio_unit_kerja = data.bio_unit_kerja;

            this.form.penilai_nama = data.penilai_nama;
            this.form.penilai_gelar_belakang = data.penilai_gelar_belakang;
            this.form.penilai_gelar_depan = data.penilai_gelar_depan;
            this.form.penilai_pangkat = data.penilai_pangkat;
            this.form.penilai_gol = data.penilai_gol;
            this.form.penilai_ruang = data.penilai_ruang;
            this.form.penilai_jabatan = data.penilai_jabatan;
            this.form.penilai_unit_kerja = data.penilai_unit_kerja;

            this.form.ap_nama = data.ap_nama;
            this.form.ap_gelar_belakang = data.ap_gelar_belakang;
            this.form.ap_gelar_depan = data.ap_gelar_depan;
            this.form.ap_pangkat = data.ap_pangkat;
            this.form.ap_gol = data.ap_gol;
            this.form.ap_ruang = data.ap_ruang;
            this.form.ap_jabatan = data.ap_jabatan;
            this.form.ap_unit_kerja = data.ap_unit_kerja;
            // this.list_data = res_data.data;
            // this.page_last = res_data.jml_data;
            // this.selectData(res_data.data[0])
        //   this.cek_load_data = false;
        });
    },

    addData : function(idnya){
        fetch(this.$store.state.url.URL_kinerja_asn + "addData", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses memperbaharui Data', 'primary', 'check_circle_outline');
            this.getView();
        });

    },












selectData: function(data) {

    this.form.id= data.id;
    this.form.nip= data.nip;
    this.form.penilai= data.penilai;
    this.form.atasan_penilai= data.atasan_penilai;

    this.form.bio_nama = data.bio_nama;
    this.form.bio_gelar_belakang = data.bio_gelar_belakang;
    this.form.bio_gelar_depan = data.bio_gelar_depan;
    this.form.bio_gol = data.bio_gol;
    this.form.bio_ruang = data.bio_ruang;
    this.form.bio_jabatan = data.bio_jabatan;
    this.form.bio_unit_kerja = data.bio_unit_kerja;

    this.form.penilai_nama = data.penilai_nama;
    this.form.penilai_gelar_belakang = data.penilai_gelar_belakang;
    this.form.penilai_gelar_depan = data.penilai_gelar_depan;
    this.form.penilai_pangkat = data.penilai_pangkat;
    this.form.penilai_gol = data.penilai_gol;
    this.form.penilai_ruang = data.penilai_ruang;
    this.form.penilai_jabatan = data.penilai_jabatan;
    this.form.penilai_unit_kerja = data.penilai_unit_kerja;

    this.form.ap_nama = data.ap_nama;
    this.form.ap_gelar_belakang = data.ap_gelar_belakang;
    this.form.ap_gelar_depan = data.ap_gelar_depan;
    this.form.ap_pangkat = data.ap_pangkat;
    this.form.ap_gol = data.ap_gol;
    this.form.ap_ruang = data.ap_ruang;
    this.form.ap_jabatan = data.ap_jabatan;
    this.form.ap_unit_kerja = data.ap_unit_kerja;
  
},

// ====================================== PAGINATE ====================================
Notify: function(message, positive, icon) {
    this.$q.notify({
    message: message,
    color: positive,
    icon: icon,
    position: "top",
    timeout: 500
    });
}

// ====================================== PAGINATE ====================================
},

mounted() {
    var get_profile = JSON.parse(localStorage.profile);
    this.form.nip = get_profile.profile.nip;


    this.getView();
}
};
